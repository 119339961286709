import axios from 'axios';
import auth from './auth';

const updateConfig = config => {
  const defaultHeaders = {
    Accept: 'application/json',
    Authorization: `Bearer ${auth.getAccessToken()}`,
  };
  config.headers = { ...defaultHeaders, ...config.headers };
  return config;
};

// send your query params as config.params
export const getData = (url, config = {}) => {
  config = updateConfig(config);
  return axios.get(url, config);
};

export const postData = (url, data, config = {}) => {
  config = updateConfig(config);
  return axios.post(url, data, config);
};

export const deleteData = (url, config = {}) => {
  config = updateConfig(config);
  return axios.delete(url, config);
};

export const putData = (url, data, config = {}) => {
  config = updateConfig(config);
  return axios.put(url, data, config);
};
