import './Performance.css';
import { NetworkPerformance } from '@cimpress-technology/supplier-performance';
import auth from '../services/auth';

function Performance() {
  return (
    <div className='width-full'>
      <NetworkPerformance accessToken={auth.getAccessToken()} componentHeight='calc(100vh - 206px)' />
    </div>
  );
}

export default Performance;
