import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'OgAxRkO7IAYFkbcKywCGkpS6w04Ox7oy';

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

export default auth;
