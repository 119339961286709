import './Operations.css';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavTab, NavTabItem, Toggle } from '@cimpress/react-components';
import { VIEWS } from '../constants/view';
import Backlog from './Backlog';
import MissedSla from './MissedSla';
import { DashboardFilters, FILTER_NAMES, useSavedFilters } from '@cimpress-technology/supplier-performance';
import auth from '../services/auth';
import { showSnackbar } from '../actions/snackbar';

function Operations({
  fulfiller: { fulfillerId, name: fulfillerName, internalFulfillerId } = {},
  hideHeading = false,
  supplierOnlyView = false,
  supplierOnlyUser = false,
  showSnackbar,
}) {
  const [showPercentage, setShowPercentage] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [child, setChild] = useState(VIEWS.BACKLOG);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSearch, setSelectedSearch] = useState();
  const { defaultFilters, savedSearchToApply, isLoading } = useSavedFilters({
    accessToken: auth.getAccessToken(),
    fulfillerId,
    fulfillerName,
    showSnackbar,
  });

  useEffect(() => {
    if (!isLoading) {
      setSelectedFilters(defaultFilters);
      setSelectedSearch(savedSearchToApply);
    }
  }, [isLoading, defaultFilters, savedSearchToApply]);

  const component =
    child === VIEWS.BACKLOG ? (
      <Backlog
        fulfillerId={fulfillerId}
        name={fulfillerName}
        internalFulfillerId={internalFulfillerId}
        showNumbers={!showPercentage}
        selectedFilters={selectedFilters}
        supplierOnlyView={supplierOnlyView}
        supplierOnlyUser={supplierOnlyUser}
      />
    ) : (
      <MissedSla
        fulfillerId={fulfillerId}
        name={fulfillerName}
        internalFulfillerId={internalFulfillerId}
        showNumbers={!showPercentage}
        supplierOnlyView={supplierOnlyView}
        supplierOnlyUser={supplierOnlyUser}
      />
    );

  return (
    <div className='performance-operations-div'>
      <div className='flex-parent' style={{ marginBottom: '10px' }}>
        <div className='main-heading'>{hideHeading ? '' : 'Operations Overview'}</div>
        <div style={{ marginLeft: 'auto', marginRight: '30px' }}>
          <Toggle on={showPercentage} onClick={() => setShowPercentage(!showPercentage)} size='sm' />
          <label style={{ padding: '4px 10px', fontWeight: '100' }}>Show as percentage</label>
        </div>
        <div
          id='operations-filter'
          className='filters-link'
          style={{ padding: '4px 10px', marginLeft: 'unset' }}
          onClick={() => setShowFilters(true)}
        >
          Filters
        </div>
        <DashboardFilters // need to be updated with actual implementation
          accessToken={auth.getAccessToken()}
          showSnackbar={showSnackbar}
          show={showFilters}
          filterList={[fulfillerId ? FILTER_NAMES.MERCHANT : FILTER_NAMES.FULFILLER, FILTER_NAMES.EXPECTED_SHIP_DATE]}
          fulfiller={{ fulfillerId, name: fulfillerName }}
          onApplyFilters={data => {
            setSelectedFilters(data);
            setShowFilters(false);
          }}
          onCancelFilters={() => {
            setShowFilters(false);
          }}
          onChangeSelectedSearch={data => {
            setSelectedSearch(data);
          }}
          selectedFilters={selectedFilters}
          selectedSearch={selectedSearch}
        />
      </div>
      {false && ( // temporarily hiding it until missedSLA screen is created
        <NavTab className='sub-nav-bar'>
          <NavTabItem active={child === VIEWS.BACKLOG}>
            <button
              className='sub-nav-item'
              style={child === VIEWS.BACKLOG ? { backgroundColor: '#EDF3F8' } : {}}
              onClick={() => {
                setChild(VIEWS.BACKLOG);
              }}
            >
              Backlog
            </button>
          </NavTabItem>
          <NavTabItem active={child === VIEWS.MISSEDSLA}>
            <button
              className='sub-nav-item'
              style={child === VIEWS.MISSEDSLA ? { backgroundColor: '#EDF3F8' } : {}}
              onClick={() => {
                setChild(VIEWS.MISSEDSLA);
              }}
            >
              Missed SLA
            </button>
          </NavTabItem>
        </NavTab>
      )}
      <div style={{ height: 'calc(100vh - 280px + 56px)', overflowY: 'scroll' }}>{component}</div>
    </div>
  );
}

export default connect(null, { showSnackbar })(Operations);
