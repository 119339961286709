import './PageNotFound.css';
import FourOhFour from '@cimpress/react-components/lib/FourOhFour';

const PageNotFound = () => (
  <div className='width-full page-not-found-padding'>
    <FourOhFour />
  </div>
);

export default PageNotFound;
