import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

function Loading() {
  return (
    <div className='text-center width-full'>
      <Spinner />
    </div>
  );
}

export default Loading;
