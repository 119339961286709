import './Backlog.css';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactTablev6, Tooltip } from '@cimpress/react-components';
import IconRatingStar from '@cimpress-technology/react-streamline-icons/lib/IconRatingStar';
import IconUser from '@cimpress-technology/react-streamline-icons/lib/IconUser';
import IconEmailSend from '@cimpress-technology/react-streamline-icons/lib/IconEmailSend';
import { teal } from '@cimpress/react-components/lib/colors';
import Loading from './common/Loading';
import {
  getBacklog,
  getFulfillerContacts,
  getMerchantContact,
  getStarredFulfillers,
  updateStarredFulfillers,
} from '../services/apis';
import { showSnackbar } from '../actions/snackbar';
import { SNACKBAR_STATUS } from '../constants/snackbarStatus';
import BacklogCard from './common/BacklogCard';
import LoadError from './common/LoadError';
import { VIEWS } from '../constants/view';
import { OPERATIONS_HEADINGS, PAGE_SIZE } from '../constants/common';
import { FILTER_NAMES } from '@cimpress-technology/supplier-performance';

function Backlog({
  fulfillerId,
  name,
  internalFulfillerId,
  showNumbers,
  showSnackbar,
  selectedFilters,
  supplierOnlyUser = false,
}) {
  const [isError, setIsError] = useState(false);
  const [backlogData, setBacklogData] = useState([]);
  const [starredBacklogData, setStarredBacklogData] = useState([]);
  const [overallBacklog, setOverallBacklog] = useState({});
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [starredFulfillers, setStarredFulfillers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const numericColumnSort = (a, b) =>
    showNumbers ? a.props.value - b.props.value : a.props.children[0] - b.props.children[0];

  const getTotalHeader = () => {
    if (showNumbers) {
      return {
        Header: OPERATIONS_HEADINGS.TOTAL_BACKLOG,
        columns: [
          { Header: 'Total', accessor: 'total', maxWidth: 135 },
          { Header: 'Delayed', accessor: 'totalDelayed', maxWidth: 135 },
        ],
        headerStyle: { fontSize: '15px' },
      };
    } else {
      return {
        Header: OPERATIONS_HEADINGS.TOTAL_BACKLOG,
        columns: [{ Header: 'Delayed', accessor: 'totalDelayed' }],
        headerStyle: { fontSize: '15px' },
      };
    }
  };

  const columns = [
    {
      Header: fulfillerId ? 'Merchant Name' : 'Fulfiller Name',
      columns: [{ Header: '', accessor: 'name', sortable: false, minWidth: 150 }],
      headerStyle: { fontSize: '15px' },
    },
    {
      Header: OPERATIONS_HEADINGS.AWAITING_FULFILLER_RESPONSE,
      columns: [
        { Header: 'Total', accessor: 'awaitingFulfillerResponseTotal', maxWidth: 135 },
        {
          Header: 'Delayed',
          accessor: 'awaitingFulfillerResponseDelayed',
          maxWidth: 135,
        },
      ],
      headerStyle: { fontSize: '15px' },
    },
    {
      Header: OPERATIONS_HEADINGS.PRODUCTION_ACCEPTED,
      columns: [
        { Header: 'Total', accessor: 'productionAcceptedTotal', maxWidth: 135 },
        { Header: 'Delayed', accessor: 'productionAcceptedDelayed', maxWidth: 135 },
      ],
      headerStyle: { fontSize: '15px' },
    },
    {
      Header: OPERATIONS_HEADINGS.PRODUCTION,
      columns: [
        { Header: 'Total', accessor: 'productionTotal', maxWidth: 135 },
        { Header: 'Delayed', accessor: 'productionDelayed', maxWidth: 135 },
      ],
      headerStyle: { fontSize: '15px' },
    },
    getTotalHeader(),
  ];

  if (!supplierOnlyUser) {
    // Actions column is not visible on Supplier-Only View
    columns.push({
      Header: 'Actions',
      columns: [{ Header: '', accessor: 'actions', sortable: false }],
      headerStyle: { fontSize: '15px' },
    });
  }

  const toggleStarred = async ({ starred, ...data }) => {
    setTableLoading(true);
    try {
      let [updatedStarredFulfillers, updatedStarredBacklogData] = [[], []];
      if (starred) {
        // un-starring a starred row
        updatedStarredFulfillers = starredFulfillers.filter(({ id }) => id !== data.id);
        updatedStarredBacklogData = starredBacklogData.filter(({ id }) => id !== data.id);
      } else {
        // starring an un-starred row
        if (starredFulfillers.length === 10) {
          showSnackbar({
            status: SNACKBAR_STATUS.DANGER,
            message: `Can't star more than 10 suppliers!`,
          });
          setTableLoading(false);
          return;
        }
        updatedStarredFulfillers = [...(starredFulfillers || []), { id: data.id, name: data.name }];
        updatedStarredBacklogData = [...(starredBacklogData || []), { ...data, starred: true }];
      }
      await updateStarredFulfillers(updatedStarredFulfillers);
      setStarredFulfillers(updatedStarredFulfillers);
      setStarredBacklogData(updatedStarredBacklogData);
      setBacklogData(previous =>
        (previous || []).map(row => (row.id === data.id ? { ...row, starred: !starred } : row)),
      );
    } catch (error) {
      console.error(error?.response?.data || error?.response || error);
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message: `Error while ${starred ? 'un-starring' : 'starring'}! Please retry.`,
      });
    }
    setTableLoading(false);
  };

  const getFulfillerContactEmails = async fulfillerId => {
    try {
      const fulfillerContacts = await getFulfillerContacts(fulfillerId);
      const operationalEmails = [],
        nonOperationalEmails = [];
      fulfillerContacts?.map(contact => {
        if (contact.operationalSupportContact && contact.email) {
          operationalEmails.push(contact.email);
        } else if (contact.email) {
          nonOperationalEmails.push(contact.email);
        }
        return contact;
      });
      if (operationalEmails.length > 0) {
        window.location.href = `mailto:${operationalEmails.join(';')};`;
      } else if (nonOperationalEmails.length > 0) {
        window.location.href = `mailto:${nonOperationalEmails.join(';')};`;
      } else {
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: 'There is no supplier email present!',
        });
      }
    } catch (error) {
      console.error(error?.response?.data || error?.response || error);
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message:
          error?.response?.status === 401 || error?.response?.status === 403
            ? `You don't have permission to access receiver's email address!`
            : 'Error while emailing! Please retry.',
      });
    }
  };

  const getMerchantContactEmail = async merchantId => {
    try {
      const merchantData = await getMerchantContact(merchantId);
      if (merchantData?.defaultOrderSupportContact?.email) {
        window.location.href = `mailto:${merchantData.defaultOrderSupportContact?.email};`;
      } else {
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: 'There is no merchant email present!',
        });
      }
    } catch (error) {
      console.error(error?.response?.data || error?.response || error);
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message:
          error?.response?.status === 401 || error?.response?.status === 403
            ? `You don't have permission to access receiver's email address!`
            : 'Error while emailing! Please retry.',
      });
    }
  };

  const calculatePercentage = (number, total) => {
    return total === 0 ? 0 : ((number / total) * 100).toFixed();
  };

  const getOopsURL = ({ fulfillerId, name, merchantId, status = 'overall', delayed = true }) => {
    const queryParams = [
      ...(fulfillerId ? [`fulfillers=${fulfillerId}:${name}`] : []),
      ...(merchantId ? [`merchants=${merchantId}`] : []),
    ];
    switch (status) {
      case 'awaitingFulfillerResponse':
        queryParams.push(delayed ? 'delayedStatuses=awaitingFulfillerResponse' : 'statuses=awaitingFulfillerResponse');
        break;
      case 'productionAccepted':
        queryParams.push(delayed ? 'delayedStatuses=productionAccepted' : 'statuses=productionAccepted');
        break;
      case 'production':
        queryParams.push(delayed ? 'delayedStatuses=production' : 'statuses=production');
        break;
      case 'overall':
        queryParams.push(
          ...(delayed
            ? [
                'delayedStatuses=awaitingFulfillerResponse',
                'delayedStatuses=productionAccepted',
                'delayedStatuses=production',
              ]
            : ['statuses=awaitingFulfillerResponse', 'statuses=productionAccepted', 'statuses=production']),
        );
        break;
      default:
        break;
    }
    const queryString = encodeURI(queryParams.join('&'));
    return `${process.env.REACT_APP_OOPS_URL}/?${queryString}`;
  };

  const getPomURL = ({ merchantId, status = 'overall' }) => {
    const { start: expectedShipStart, end: expectedShipEnd } = selectedFilters[FILTER_NAMES.EXPECTED_SHIP_DATE] || {};
    const queryParams = [
      ...(merchantId ? [`fulltext=${merchantId}`] : []),
      ...(expectedShipStart ? [`expectedShipTimeFrom=${expectedShipStart}`] : []),
      ...(expectedShipEnd ? [`expectedShipTimeTo=${expectedShipEnd}`] : []),
    ];
    switch (status) {
      case 'awaitingFulfillerResponse':
        queryParams.push('status=new');
        break;
      case 'productionAccepted':
        queryParams.push('status=accepted');
        break;
      case 'production':
        queryParams.push('status=production');
        break;
      case 'overall':
        queryParams.push('status=new,accepted,production');
        break;
      default:
        break;
    }
    const queryString = encodeURI(queryParams.join('&'));
    return `${process.env.REACT_APP_POM_URL}/items?${queryString}`;
  };

  const DelayedCells = ({ fulfillerId, name, merchantId, status, value }) => {
    // DelayedCells needs both fulfillerId & merchantId to generate a link to orderoperations UI.
    // For network view merchantId needs to be empty so we are checking with fulfillerId if it's network view or not
    return (
      <div>
        {supplierOnlyUser ? (
          <span className='maroon-text'>{value}</span>
        ) : (
          <Tooltip direction='top' contents='Open in OOPS'>
            <a
              href={getOopsURL({ fulfillerId, name, merchantId, status })}
              className='maroon-text'
              target='_blank'
              rel='noreferrer noopener'
            >
              {value}
            </a>
          </Tooltip>
        )}
      </div>
    );
  };

  const TotalCells = ({ fulfillerId, name, merchantId, status, value }) => {
    // Only for supplierOnlyUser we allow redirection to POM
    return (
      <div>
        {supplierOnlyUser ? (
          <Tooltip direction='top' contents='Open in POM'>
            <a
              href={getPomURL({ merchantId, status })}
              className='black-text'
              target='_blank'
              rel='noreferrer noopener'
            >
              {value}
            </a>
          </Tooltip>
        ) : (
          <Tooltip direction='top' contents='Open in OOPS'>
            <a
              href={getOopsURL({ fulfillerId, name, merchantId, status, delayed: false })}
              className='black-text'
              target='_blank'
              rel='noreferrer noopener'
            >
              {value}
            </a>
          </Tooltip>
        )}
      </div>
    );
  };

  const formatData = data => {
    return data.map(({ starred = false, ...row }) => {
      let data = {
        ...row,
        name: row.name || row.id,
        awaitingFulfillerResponseTotal: (
          <TotalCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='awaitingFulfillerResponse'
            value={row.awaitingFulfillerResponse}
          />
        ),
        awaitingFulfillerResponseDelayed: (
          <DelayedCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='awaitingFulfillerResponse'
            value={row.awaitingFulfillerResponseDelayed}
          />
        ),
        productionAcceptedTotal: (
          <TotalCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='productionAccepted'
            value={row.productionAccepted}
          />
        ),
        productionAcceptedDelayed: (
          <DelayedCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='productionAccepted'
            value={row.productionAcceptedDelayed}
          />
        ),
        productionTotal: (
          <TotalCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='production'
            value={row.production}
          />
        ),
        productionDelayed: (
          <DelayedCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            status='production'
            value={row.productionDelayed}
          />
        ),
        total: <TotalCells merchantId={fulfillerId ? row.id : ''} value={row.total} />,
        totalDelayed: (
          <DelayedCells
            fulfillerId={internalFulfillerId || row.internalFulfillerId}
            name={name || row.name}
            merchantId={fulfillerId ? row.id : ''}
            value={row.totalDelayed}
          />
        ),

        actions: (
          <div className='operations-actions'>
            {!fulfillerId && (
              <>
                <Tooltip direction='top' contents={starred ? 'Unstar Fulfiller' : 'Star Fulfiller'}>
                  <IconRatingStar
                    className='clickable'
                    color={teal.base}
                    weight={starred ? 'fill' : ''}
                    onClick={() => toggleStarred({ ...row, starred })}
                  />
                </Tooltip>
                <Tooltip direction='top' contents='View Fulfiller Profile'>
                  <IconUser
                    className='clickable'
                    color={teal.base}
                    onClick={() => window.open(`${VIEWS.OPERATIONS}/${row.id}`, '_blank')}
                  />
                </Tooltip>
              </>
            )}
            <Tooltip direction='top' contents={fulfillerId ? 'Email Merchant' : 'Email Fulfiller'}>
              <IconEmailSend
                className='clickable'
                color={teal.base}
                onClick={() => {
                  fulfillerId ? getMerchantContactEmail(row.id) : getFulfillerContactEmails(row.id);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
      if (!showNumbers) {
        data = {
          ...data,
          awaitingFulfillerResponseTotal: <span>{calculatePercentage(row.awaitingFulfillerResponse, row.total)}%</span>,
          awaitingFulfillerResponseDelayed: (
            <span className='maroon-text'>{calculatePercentage(row.awaitingFulfillerResponseDelayed, row.total)}%</span>
          ),
          productionAcceptedTotal: <span>{calculatePercentage(row.productionAccepted, row.total)}%</span>,
          productionAcceptedDelayed: (
            <span className='maroon-text'>{calculatePercentage(row.productionAcceptedDelayed, row.total)}%</span>
          ),
          productionTotal: <span>{calculatePercentage(row.production, row.total)}%</span>,
          productionDelayed: (
            <span className='maroon-text'>{calculatePercentage(row.productionDelayed, row.total)}%</span>
          ),
          total: `-`,
          totalDelayed: <span className='maroon-text'>{calculatePercentage(row.totalDelayed, row.total)}%</span>,
        };
      }

      return {
        ...data,
        awaitingFulfillerResponse: data.awaitingFulfillerResponse,
        productionAccepted: data.productionAccepted,
        production: data.production,
        total: data.total,
      };
    });
  };

  const pageChangeHandler = async page => {
    setTableLoading(true);
    const data = await getBacklog({ fulfillerId, page, filters: selectedFilters || {} });

    setBacklogData(
      (data?.individualBacklog || []).map(row => ({
        ...row,
        starred: starredFulfillers.some(({ id }) => id === row.id),
      })),
    );
    setOverallBacklog(data.overallBacklog);
    setTotalPages(Math.ceil(data.totalRows / PAGE_SIZE));
    // update starred entries (if any) to keep the starred data consistent with the all data
    setStarredBacklogData(previous =>
      previous?.map(row => {
        data?.individualBacklog?.forEach(newRow => {
          if (newRow.id === row.id) return newRow;
        });
        return row;
      }),
    );
    setTableLoading(false);
  };

  useEffect(() => {
    const initializeTables = async () => {
      setLoading(true);
      setTableLoading(true);
      try {
        const starredFulfillerData = !fulfillerId
          ? ((await getStarredFulfillers()) || []).map(({ id, fulfillerId, name }) => ({
              id: id || fulfillerId, // fulfillerId is because of old code implementation
              name,
            }))
          : [];

        const [allData, starredData] = await Promise.all([
          getBacklog({ fulfillerId, filters: selectedFilters || {} }),
          starredFulfillerData?.length
            ? getBacklog({ filters: { ...selectedFilters, [FILTER_NAMES.FULFILLER]: starredFulfillerData } })
            : [],
        ]);

        setBacklogData(
          (allData?.individualBacklog || []).map(row => ({
            ...row,
            starred: starredFulfillerData.some(({ id }) => id === row.id),
          })),
        );
        setStarredBacklogData((starredData?.individualBacklog || []).map(row => ({ ...row, starred: true })));
        setOverallBacklog(allData?.overallBacklog || {});
        setStarredFulfillers(starredFulfillerData);
        setTotalPages(Math.ceil(allData?.totalRows / PAGE_SIZE) || 0);
      } catch (error) {
        console.error(error?.response?.data || error?.response || error);
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: 'Error while loading Backlog data! Try reloading.',
        });
        setIsError(true);
      }
      setTableLoading(false);
      setLoading(false);
    };

    initializeTables();
  }, [fulfillerId, showSnackbar, selectedFilters]);

  const formatCardData = data => {
    return showNumbers
      ? data?.toLocaleString()
      : `${calculatePercentage(
          data,
          overallBacklog.awaitingFulfillerResponse + overallBacklog.productionAccepted + overallBacklog.production,
        )}%`;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : isError ? (
        <LoadError />
      ) : (
        <>
          <div className='card-deck' style={{ overflowX: 'scroll', justifyContent: 'center' }}>
            <BacklogCard
              heading={OPERATIONS_HEADINGS.AWAITING_FULFILLER_RESPONSE}
              total={
                <TotalCells
                  status='awaitingFulfillerResponse'
                  value={formatCardData(overallBacklog.awaitingFulfillerResponse)}
                />
              }
              delayed={
                <DelayedCells
                  fulfillerId={internalFulfillerId}
                  name={name}
                  status='awaitingFulfillerResponse'
                  value={formatCardData(overallBacklog.awaitingFulfillerResponseDelayed)}
                />
              }
            />
            <BacklogCard
              heading={OPERATIONS_HEADINGS.PRODUCTION_ACCEPTED}
              total={
                <TotalCells status='productionAccepted' value={formatCardData(overallBacklog.productionAccepted)} />
              }
              delayed={
                <DelayedCells
                  fulfillerId={internalFulfillerId}
                  name={name}
                  status='productionAccepted'
                  value={formatCardData(overallBacklog.productionAcceptedDelayed)}
                />
              }
            />
            <BacklogCard
              heading={OPERATIONS_HEADINGS.PRODUCTION}
              total={<TotalCells status='production' value={formatCardData(overallBacklog.production)} />}
              delayed={
                <DelayedCells
                  fulfillerId={internalFulfillerId}
                  name={name}
                  status='production'
                  value={formatCardData(overallBacklog.productionDelayed)}
                />
              }
            />
            <BacklogCard
              heading={OPERATIONS_HEADINGS.TOTAL_BACKLOG}
              // total={formatCardData(overallBacklog.total)}
              total={
                <TotalCells
                  value={formatCardData(
                    overallBacklog.awaitingFulfillerResponse +
                      overallBacklog.productionAccepted +
                      overallBacklog.production,
                  )}
                />
              }
              delayed={
                <DelayedCells
                  fulfillerId={internalFulfillerId}
                  name={name}
                  // value={formatCardData(overallBacklog.totalDelayed)}
                  value={formatCardData(
                    overallBacklog.awaitingFulfillerResponseDelayed +
                      overallBacklog.productionAcceptedDelayed +
                      overallBacklog.productionDelayed,
                  )}
                />
              }
            />
          </div>
          <div>
            {starredBacklogData?.length > 0 && (
              <>
                <h3 className='sub-heading'>Starred Fulfillers:</h3>
                <div style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
                  <ReactTablev6
                    columns={columns}
                    data={starredBacklogData && formatData(starredBacklogData)}
                    loading={tableLoading}
                    showPagination={false}
                    defaultSortMethod={numericColumnSort}
                    minRows={0}
                    getTheadTrProps={() => ({
                      style: {
                        height: '35px',
                      },
                    })}
                    getTheadGroupTrProps={() => ({
                      style: {
                        height: '35px',
                      },
                    })}
                  />
                </div>
              </>
            )}
            {backlogData?.length > 0 && (
              <>
                <h3 className='sub-heading'>{fulfillerId ? 'All Merchants:' : 'All Fulfillers:'}</h3>
                <div style={{ overflowY: 'scroll' }}>
                  <ReactTablev6
                    columns={columns}
                    data={backlogData && formatData(backlogData)}
                    loading={tableLoading}
                    pages={totalPages}
                    pageSize={PAGE_SIZE}
                    minRows={0}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    defaultSortMethod={numericColumnSort}
                    onPageChange={e => pageChangeHandler(e)}
                    getTheadTrProps={() => ({
                      style: {
                        height: '35px',
                      },
                    })}
                    getTheadGroupTrProps={() => ({
                      style: {
                        height: '35px',
                      },
                    })}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default connect(null, { showSnackbar })(Backlog);
