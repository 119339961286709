import './ViewPort.css';
import { connect } from 'react-redux';
import { Snackbar } from '@cimpress/react-components/lib';
import { hideSnackbar } from '../../actions/snackbar';

function ViewPort({ children, snackbar, hideSnackbar }) {
  return (
    <div className='flex-parent main-div'>
      <Snackbar show={snackbar.show} status={snackbar.status} onHideSnackbar={hideSnackbar}>
        {snackbar.message}
      </Snackbar>
      {children}
    </div>
  );
}

export default connect(({ snackbar }) => ({ snackbar }), { hideSnackbar })(ViewPort);
