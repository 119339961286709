import './FulfillerView.css';
import { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useQuery from '../customHooks/useQuery';
import defaultFulfillerLogo from '../static/fulfiller.jpg';
import {
  FulfillerPerformance,
  MerchantFulfillerPerformance,
  FILTER_PRECEDENCE,
} from '@cimpress-technology/supplier-performance';
import { NavTab, NavTabItem, Select, Tooltip } from '@cimpress/react-components/lib';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import { teal } from '@cimpress/react-components/lib/colors';
import { showSnackbar } from '../actions/snackbar';
import Loading from './common/Loading';
import PageNotFound from './common/PageNotFound';
import { getFulfillers, getFulfillerDetails, getFulfillerLogo } from '../services/apis';
import auth from '../services/auth';
import { FULFILLER_STATUS, FULFILLER_STATUS_UI } from '../constants/fulfillerStatus';
import { SNACKBAR_STATUS } from '../constants/snackbarStatus';
import { VIEWS } from '../constants/view';
import Operations from './Operations';

function FulfillerView({
  view,
  showSnackbar,
  allowSupplierDropdown = true,
  supplierOnlyView = false,
  supplierOnlyUser = false,
  hideLeftNavBar = false,
}) {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname?.split('/')[1];
  const query = useQuery(location.search);

  // destructuring params to get fulfillerId.
  const { fulfillerId } = useParams();
  // this query parameter is used to identify if fulfiller view is being opened from network dashboard
  const parentView = query.get('view');

  const [loading, setLoading] = useState(true);
  // pageNotFound will be set to false if its a valid fulfillerId and if no errors occur
  const [pageNotFound, setPageNotFound] = useState(true);
  const [selectedTab, setSelectedTab] = useState(path);
  const [fulfillerData, setFulfillerData] = useState({
    logoUrl: '',
    name: 'Supplier Name',
    email: 'Supplier Email',
    status: 'Supplier Status',
  });
  const [fulfillerOptions, setFulfillerOptions] = useState([]);
  const [selectingFulfiller, setSelectingFulfiller] = useState(false);

  const isNumberString = value => /^\d+$/.test(value);

  const component =
    view === VIEWS.OPERATIONS ? (
      <Operations
        hideHeading={true}
        fulfiller={{ fulfillerId, name: fulfillerData.name, internalFulfillerId: fulfillerData.internalFulfillerId }}
        supplierOnlyView={supplierOnlyView}
        supplierOnlyUser={supplierOnlyUser}
      />
    ) : supplierOnlyView ? (
      // fulfiller view
      <FulfillerPerformance
        accessToken={auth.getAccessToken()}
        fulfiller={{ fulfillerId, name: fulfillerData.name }}
        hideHeading={true}
        componentHeight='calc(100vh - 247px + 47px)'
        filterPrecedence={parentView ? FILTER_PRECEDENCE.LAST : FILTER_PRECEDENCE.DEFAULT}
      />
    ) : (
      // merchant fulfiller view
      <MerchantFulfillerPerformance
        accessToken={auth.getAccessToken()}
        fulfiller={{ fulfillerId, name: fulfillerData.name }}
        hideHeading={true}
        componentHeight='calc(100vh - 247px + 47px)'
        filterPrecedence={parentView ? FILTER_PRECEDENCE.LAST : FILTER_PRECEDENCE.DEFAULT}
      />
    );
  // Fulfiller view has a sub-navbar of its own to switch between performance & operations
  // This function handles updating the style and pushes the route in the browser
  const handleSubNavClick = subView => {
    if (subView === VIEWS.OPERATIONS) {
      setSelectedTab(VIEWS.OPERATIONS);
      history.push(`/${VIEWS.OPERATIONS}/${fulfillerId}`);
    } else {
      setSelectedTab(VIEWS.PERFORMANCE);
      history.push(`/${VIEWS.PERFORMANCE}/${fulfillerId}`);
    }
  };

  const handleSelectFulfiller = ({ value }) => {
    setSelectingFulfiller(false);
    if (path === VIEWS.OPERATIONS) {
      history.push(`/${VIEWS.OPERATIONS}/${value}`);
    } else {
      history.push(`/${VIEWS.PERFORMANCE}/${value}`);
    }
  };

  useEffect(() => {
    async function getFulfillerInfo() {
      // api call to get fulfiller data based on fulfillerId (alphanumeric/global)
      setLoading(true);
      if (!isNumberString(fulfillerId)) {
        try {
          const response = await getFulfillerDetails(fulfillerId);
          const {
            name,
            internalFulfillerId,
            email,
            links: { fulfillerLogo: { href: logoUrl } = {} },
          } = response;
          let status;
          for (const KEY in FULFILLER_STATUS) {
            if (response[FULFILLER_STATUS[KEY]]?.value) {
              status = FULFILLER_STATUS_UI[KEY];
            }
          }

          // fetch fulfiller logo
          let logoSrc;
          if (logoUrl) {
            try {
              logoSrc = await getFulfillerLogo({ url: logoUrl });
            } catch (error) {
              logoSrc = null;
            }
          }

          setFulfillerData({
            logoUrl: logoSrc || '',
            name: name || 'Supplier Name (?)',
            email: email || 'Supplier Email (?)',
            status: status || 'Supplier Status (?)',
            internalFulfillerId,
          });
          setPageNotFound(false); // set PageNotFound to false if everything goes well
        } catch (error) {
          console.error(error?.response?.data || error?.response || error);
          showSnackbar({
            status: SNACKBAR_STATUS.DANGER,
            message: 'Error while fetching the fulfiller details!',
          });
          if (error?.response?.status === 403 || error?.response?.status === 401) {
            setFulfillerData({
              logoUrl: '',
              name: 'Supplier Name (?)',
              email: 'Supplier Email (?)',
              status: 'Supplier Status (?)',
            });
            setPageNotFound(false);
          }
        }
      }
      setLoading(false);
    }

    async function getFulfillerOptions() {
      try {
        const fulfillers = (await getFulfillers()).map(({ name, fulfillerId }) => ({
          label: name,
          value: fulfillerId,
        }));
        setFulfillerOptions(fulfillers);
      } catch (error) {
        console.error(error?.response?.data || error?.response || error);
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: 'Error while fetching the fulfillers list!',
        });
      }
    }

    async function init() {
      const promises = [getFulfillerInfo()];
      if (allowSupplierDropdown) promises.push(getFulfillerOptions());
      await Promise.all(promises);
    }

    init();
  }, [fulfillerId, showSnackbar, allowSupplierDropdown]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : pageNotFound ? (
        <PageNotFound />
      ) : (
        <>
          <div className='performance-left-div'>
            <div className='performance-left-div-top'>
              <div>
                <img
                  className='fulfiller-logo'
                  src={fulfillerData.logoUrl || defaultFulfillerLogo}
                  alt='fulfiller logo'
                />
              </div>
              <div className='fulfiller-name'>
                {fulfillerData.name}&nbsp;&nbsp;
                {allowSupplierDropdown && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectingFulfiller(prevValue => !prevValue);
                    }}
                  >
                    <Tooltip direction='top' contents='Select Supplier'>
                      <IconPencilAlt color={teal.base} />
                    </Tooltip>
                  </span>
                )}
              </div>
              {selectingFulfiller && (
                <Select
                  key={'fulfiller-select'}
                  name={'Fulfiller Select'}
                  isClearable={false}
                  isMulti={false}
                  label={'Select a Supplier'}
                  options={fulfillerOptions}
                  onChange={value => handleSelectFulfiller(value)}
                  value={{ label: fulfillerData?.name, value: fulfillerData?.name }}
                />
              )}
              <div className='active-for-4-months'>{fulfillerData.status}</div>
              <div className='email-fulfiller'>{fulfillerData.email}</div>
            </div>
            {!hideLeftNavBar && (
              <>
                <NavTab vertical={true}>
                  <NavTabItem
                    active={selectedTab === VIEWS.PERFORMANCE}
                    style={{ width: '100%', margin: 0, borderTop: '1px solid #E1E6EB' }} // borderTop only for 1st tab
                  >
                    <button
                      className='sub-nav-item-vertical'
                      style={selectedTab === VIEWS.PERFORMANCE ? { backgroundColor: '#EDF3F8' } : {}}
                      onClick={() => {
                        handleSubNavClick(VIEWS.PERFORMANCE);
                      }}
                    >
                      Performance Overview
                    </button>
                  </NavTabItem>
                  <NavTabItem active={selectedTab === VIEWS.OPERATIONS} style={{ width: '100%', margin: 0 }}>
                    <button
                      className='sub-nav-item-vertical'
                      style={selectedTab === VIEWS.OPERATIONS ? { backgroundColor: '#EDF3F8' } : {}}
                      onClick={() => {
                        handleSubNavClick(VIEWS.OPERATIONS);
                      }}
                    >
                      Daily Operations
                    </button>
                  </NavTabItem>
                </NavTab>
              </>
            )}
          </div>
          <div className='performance-right-div'>{component}</div>
        </>
      )}
    </>
  );
}

export default connect(null, { showSnackbar })(FulfillerView);
