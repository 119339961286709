export const FULFILLER_STATUS = {
  SETUP_IN_PROGRESS: 'setupInProgress',
  ACTIVE: 'active',
  IDLE: 'idle',
  INACTIVE: 'inactive',
  RETIRED: 'retired',
};

export const FULFILLER_STATUS_UI = {
  SETUP_IN_PROGRESS: 'Setup in progress',
  ACTIVE: 'Active',
  IDLE: 'Idle',
  INACTIVE: 'Inactive',
  RETIRED: 'Retired',
};
