import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { FeedbackSticker } from '@cimpress-technology/react-feedback';
import auth from './services/auth';
import { fullStoryInit, fullStoryIdentify } from './services/fullstory';
import Forbidden from './components/common/Forbidden';
import LoadError from './components/common/LoadError';
import Loading from './components/common/Loading';
import PageNotFound from './components/common/PageNotFound';
import Shell from './components/common/Shell';
import Performance from './components/Performance';
import FulfillerView from './components/FulfillerView';
import fulfillerView from './guards/fulfillerView';
import merchantView from './guards/merchantView';
import { VIEWS, ISSUE } from './constants/view';
import Operations from './components/Operations';

// Forcefully login user if token expires
auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));

function App() {
  const [authenticating, setAuthenticating] = useState(false);
  const [authenticationError, setAuthenticationError] = useState();

  useEffect(() => {
    const identifyUserForFullstory = () => {
      const profile = auth.getProfile();
      if (profile) fullStoryIdentify(profile.sub, profile.name, profile.email);
    };

    const authenticateUser = async () => {
      fullStoryInit(); // initialise fullstory
      if (!auth.isLoggedIn()) {
        // authenticate user if not already logged in
        setAuthenticating(true);
        try {
          await auth.ensureAuthentication({ nextUri: window.location.pathname + window.location.search });
        } catch (error) {
          console.error(error);
          setAuthenticationError(error);
        }
        setAuthenticating(false);
      }
      identifyUserForFullstory(); // sending user info to fullstory
    };

    authenticateUser();
  }, []);

  return (
    <>
      {authenticating ? (
        <Loading />
      ) : auth.isLoggedIn() && auth.getAccessToken() ? (
        <Router basename={process.env.REACT_APP_ROUTER_BASENAME || ''}>
          <GuardProvider loading={Loading} error={LoadError}>
            <Shell>
              <Switch>
                <GuardedRoute exact path='/(index.html)?' component={Performance} guards={[merchantView]} />
                <GuardedRoute exact path={`/${VIEWS.PERFORMANCE}`} component={Performance} guards={[merchantView]} />
                <GuardedRoute
                  exact
                  path={`/${VIEWS.PERFORMANCE}/:fulfillerId`}
                  render={props => <FulfillerView view={VIEWS.PERFORMANCE} {...props} />}
                  guards={[fulfillerView]}
                />
                <GuardedRoute exact path={`/${VIEWS.OPERATIONS}`} component={Operations} guards={[merchantView]} />
                <GuardedRoute
                  exact
                  path={`/${VIEWS.OPERATIONS}/:fulfillerId`}
                  render={props => <FulfillerView view={VIEWS.OPERATIONS} {...props} />}
                  guards={[fulfillerView]}
                />
                <GuardedRoute exact path={`/${ISSUE.FORBIDDEN}`} component={Forbidden} />
                <GuardedRoute path='*' component={PageNotFound} />
              </Switch>
              <FeedbackSticker
                title={'Feedback?'}
                accessToken={auth.getAccessToken()}
                campaignId={'88e3653e-0ef8-11ec-8414-812accf32414'}
                questionId={'03e5817c-0ef9-11ec-8414-812accf32414'}
              />
            </Shell>
          </GuardProvider>
        </Router>
      ) : authenticationError ? (
        <div>Unexpected error encountered. {authenticationError.message}</div>
      ) : null}
    </>
  );
}

export default App;
