import './BacklogCard.css';
import { Card } from '@cimpress/react-components';

function BacklogCard({ heading, total, delayed }) {
  return (
    <Card className='card-base' style={{ minWidth: '300px' }}>
      <h2 className='card-heading'>{heading}</h2>
      <div className='card-content'>
        <div style={{ margin: '5px' }}>
          <h4 className='grey-text'>Total</h4>
          <h1 className='card-heading'>{total}</h1>
        </div>
        <div style={{ margin: '5px' }}>
          <h4 className='grey-text'>Delayed</h4>
          <h1 className='card-heading maroon-text'>{delayed}</h1>
        </div>
      </div>
    </Card>
  );
}

export default BacklogCard;
