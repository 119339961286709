import _ from 'lodash';

// configure merchants to be merged
export const MERGE_BUSINESS = [
  {
    displayName: 'VCS',
    prefix: 'vcs',
  },
];

/**
 * @description helper to merge similar merchants as one
 * @param {Array} merchants list of merchants
 * @returns {Array} list of merchants with merged businesses
 */
export function mergeMerchants(merchants = []) {
  const mergedMerchant = [];
  MERGE_BUSINESS.forEach(business => {
    mergedMerchant.push({
      displayName: business.displayName,
      merchantId: merchants
        .filter(merchant => _.lowerCase(merchant.merchantId).startsWith(business.prefix))
        .map(({ merchantId }) => merchantId),
    });
    _.remove(merchants, merchant => _.lowerCase(merchant.merchantId).startsWith(business.prefix));
  });
  return _.sortBy([...mergedMerchant, ...merchants], 'displayName');
}

/**
 * @description filter out data with passed regex for particular field
 * @param {Array} data list to be filtered
 * @param {String} fieldName comparison/predicate field for filter
 * @param {String} regex string equivalent of regex
 * @returns {Array} filtered list of data
 */
export function filterTestValues({ data = [], fieldName, regex }) {
  regex = new RegExp(regex, 'i');
  return data.filter(item => item[fieldName]?.trim().length > 0 && !regex.test(item[fieldName]));
}

export function statusMapper(data) {
  const mappedStatus = {
    id: data?.id,
    name: data?.name || '',
    internalFulfillerId: data?.internalFulfillerId || '',
    awaitingFulfillerResponse:
      (data?.awaitingFulfillerResponse?.onTime || 0) + (data?.awaitingFulfillerResponse?.delayed || 0),
    productionAccepted: (data?.productionAccepted?.onTime || 0) + (data?.productionAccepted?.delayed || 0),
    production: (data?.production?.onTime || 0) + (data?.production?.delayed || 0),
    awaitingFulfillerResponseDelayed: data?.awaitingFulfillerResponse?.delayed || 0,
    productionAcceptedDelayed: data?.productionAccepted?.delayed || 0,
    productionDelayed: data?.production?.delayed || 0,
    total: (data?.total?.onTime || 0) + (data?.total?.delayed || 0),
    totalDelayed: data?.total?.delayed || 0,
  };
  return mappedStatus;
}

export function backlogMapper(backlogs) {
  const individualBacklog = backlogs.individualBacklog.map(backlog => statusMapper(backlog));
  const overallBacklog = statusMapper(backlogs);
  return {
    individualBacklog,
    overallBacklog,
    totalRows: backlogs?.totalRows || 0,
  };
}
