import { getUserAccessInfo } from '../services/apis';
import { VIEWS, ISSUE } from '../constants/view';

const merchantView = async (to, from, next) => {
  try {
    const [path] = from?.location?.pathname?.split('/')?.slice(1) || [];
    const { userHasMerchantAccess, userHasFulfillerAccess, userFirstFulfillerId, fulfillerPermissions } =
      await getUserAccessInfo();
    // redirect based on merchant/fulfiller access
    if (userHasMerchantAccess) next();
    else if (userHasFulfillerAccess) {
      if (userFirstFulfillerId) next.redirect(`/${path || VIEWS.PERFORMANCE}/${userFirstFulfillerId}`);
      // The principal must have [view:profile] permissions on fulfillers resourceType
      throw new Error(
        `[view:profile] permission missing on fulfillers resourceType for identifiers[${fulfillerPermissions
          .map(permission => permission.identifier)
          .join(',')}]`,
      );
    }
    next.redirect(`/${ISSUE.FORBIDDEN}`);
  } catch (error) {
    console.error(error?.response?.data || error?.response || error);
    throw error;
  }
};

export default merchantView;
