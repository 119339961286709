export const VIEWS = {
  PERFORMANCE: 'overview',
  OPERATIONS: 'operations',
  BACKLOG: 'backlog',
  MISSEDSLA: 'missed-sla',
};

export const ISSUE = {
  FORBIDDEN: 'forbidden',
};
