import React from 'react';
import { shapes } from '@cimpress/react-components';

const { Robot } = shapes;

const LoadError = () => (
  <div style={{ padding: '20px', width: '100%', textAlign: 'center' }}>
    <Robot status='danger' style={{ display: 'inline-block' }} />
    <p>
      Something went wrong!
      <br />
      Please contact <a href={'mailto:feds@cimpress.com'}>Feds Squads</a>.
    </p>
  </div>
);
export default LoadError;
