import { getData, putData } from './http';
import auth from './auth';
import { filterTestValues, backlogMapper } from './helpers';
import { ACCOUNT_ID, CANONICAL_ID, CUSTOMIZR_OPERATIONS_KEY, PAGE_SIZE, STARRED_FULFILLERS } from '../constants/common';
import { FILTER_NAMES } from '@cimpress-technology/supplier-performance';

async function getFulfillers() {
  const config = {
    params: {
      showFlags: false,
    },
  };
  const url = `${process.env.REACT_APP_FULFILLER_IDENTITY_URL}/v1/fulfillers`;
  const { data } = await getData(url, config);
  // filtering out some unnecessary values
  return filterTestValues({ data, fieldName: 'name', regex: 'test' });
}

async function getStarredFulfillers() {
  try {
    const url = `${process.env.REACT_APP_CUSTOMIZR_URL}/v1/resources/${CUSTOMIZR_OPERATIONS_KEY}/settings`;
    const { data } = await getData(url);
    return data[STARRED_FULFILLERS];
  } catch (error) {
    if (error.response?.status === 404) return [];
    throw error;
  }
}

async function updateStarredFulfillers(updatedStarredFulfillers) {
  const url = `${process.env.REACT_APP_CUSTOMIZR_URL}/v1/resources/${CUSTOMIZR_OPERATIONS_KEY}/settings`;
  const { data } = await putData(url, { STARRED_FULFILLERS: updatedStarredFulfillers });
  return data[STARRED_FULFILLERS];
}

// id is alphanumeric/global fulfillerId
async function getFulfillerDetails(fulfillerId) {
  let url = `${process.env.REACT_APP_FULFILLER_IDENTITY_URL}/v1/fulfillers/${fulfillerId}`;
  const config = {
    params: {
      showFlags: 'true',
    },
  };
  const { data } = await getData(url, config);
  return data;
}

async function getFulfillerLogo({ url }) {
  const config = {
    headers: {
      Accept: 'image/*',
    },
    responseType: 'blob',
  };
  const { data } = await getData(url, config);
  if (data) return URL.createObjectURL(data);
  return;
}

async function getMissedSla(fulfillers) {
  await delay(1000);
  const data = fulfillers.map(({ fulfillerId, name }) => ({
    fulfillerId,
    name,
    ontime: Math.floor(Math.random() * 100) + 1,
    shipped: Math.floor(Math.random() * 100) + 1,
    production: Math.floor(Math.random() * 100) + 1,
    accepted: Math.floor(Math.random() * 100) + 1,
  }));
  return data;
}

async function getBacklog({
  fulfillerId,
  page = 0,
  filters: {
    [FILTER_NAMES.FULFILLER]: fulfillerFilter,
    [FILTER_NAMES.MERCHANT]: merchantFilter,
    [FILTER_NAMES.EXPECTED_SHIP_DATE]: expectedShipDateFilter,
  } = {},
}) {
  try {
    const queryParams = [
      ...(!fulfillerId && fulfillerFilter?.length
        ? [`fulfillerIds=${fulfillerFilter.map(fulfiller => fulfiller.id || fulfiller.fulfillerId)}`]
        : []),
      ...(fulfillerId && merchantFilter?.length
        ? [`merchantIds=${merchantFilter.map(merchant => merchant.id || merchant.merchantId)}`]
        : []),
      ...(expectedShipDateFilter?.start && expectedShipDateFilter?.end
        ? [
            `expectedShipDateStart=${new Date(
              expectedShipDateFilter.start,
            ).toISOString()}&expectedShipDateEnd=${new Date(`${expectedShipDateFilter.end} 23:59:59`).toISOString()}`,
          ]
        : []),
      [`offset=${PAGE_SIZE * page}&limit=${PAGE_SIZE}`],
    ];
    const queryString = encodeURI(queryParams.join('&'));
    const url = `${process.env.REACT_APP_SUPPLIER_PERFORMANCE_URL}/v1${
      fulfillerId ? `/supplierBacklog/${fulfillerId}` : '/networkBacklog'
    }?${queryString}`;
    const { data } = await getData(url);
    return data ? backlogMapper(data) : [];
  } catch (error) {
    if (error.response?.status === 404) return [];
    throw error;
  }
}

async function getFulfillerContacts(fulfillerId) {
  let url = `${process.env.REACT_APP_FULFILLER_IDENTITY_URL}/v1/fulfillers/${fulfillerId}/contacts`;
  const { data } = await getData(url);
  return data;
}

// returns the principal's permission(s) on a particular resource_type
async function getUserPermissions({ principal, resourceType, includePermissions, permissions }) {
  const url = `${process.env.REACT_APP_COAM_URL}/principals/${principal}/permissions/${resourceType}`;
  const config = {};
  if (includePermissions && permissions.length) {
    config.params = {
      include: includePermissions,
      permissionFilter: permissions,
    };
  }
  const { data } = await getData(url, config);
  return data;
}

async function getUserAccessInfo({ fulfillerId } = {}) {
  // helper function to get first fulfillerId
  const getFirstFulfillerId = async fulfillerPermissions => {
    if (fulfillerPermissions.length) {
      const [fulfiller] = await getFulfillers();
      return fulfiller?.fulfillerId;
    }
  };
  // helper function to get fulfillerDetails
  const getFulfillerData = async fulfillerId => {
    try {
      return await getFulfillerDetails(fulfillerId);
    } catch (error) {
      console.error(error?.response?.data || error?.response || error);
      if (error?.response?.status === 401 || error?.response?.status === 403) return;
      throw error;
    }
  };
  // main logic
  const { [CANONICAL_ID]: principal, [ACCOUNT_ID]: userAccountId } = auth.getProfile();
  const [merchantPermissions, fulfillerPermissions, fulfillerDetails] = await Promise.all([
    getUserPermissions({
      principal,
      resourceType: 'merchants',
      includePermissions: true,
      permissions: ['read:order'],
    }),
    getUserPermissions({
      principal,
      resourceType: 'fulfillers',
      includePermissions: true,
      permissions: ['read:order'],
    }),
    ...(fulfillerId ? [getFulfillerData(fulfillerId)] : []),
  ]);

  const userHasMerchantAccess = !!merchantPermissions.length;
  const userHasFulfillerAccess = !!(fulfillerId
    ? fulfillerPermissions.some(({ identifier } = {}) => identifier === fulfillerId || identifier === '*')
    : fulfillerPermissions.length);
  const userFirstFulfillerId = fulfillerId || (await getFirstFulfillerId(fulfillerPermissions));
  const userHasSameAccountAsFulfiller = !!(
    userHasFulfillerAccess && userAccountId === fulfillerDetails?.links?.account?.name
  );

  return {
    merchantPermissions,
    fulfillerPermissions,
    userHasMerchantAccess,
    userHasFulfillerAccess,
    userFirstFulfillerId,
    userHasSameAccountAsFulfiller,
  };
}

async function getMerchants() {
  const url = `${process.env.REACT_APP_MERCHANT_CONFIG_URL}/v0/merchants`;
  const config = {
    params: {
      verbose: false,
    },
  };
  const { data } = await getData(url, config);
  // filter out test values & merge similar merchant(s) as one
  return filterTestValues({ data, fieldName: 'displayName', regex: 'test' });
}

async function getMerchantContact(merchantId) {
  let url = `${process.env.REACT_APP_MERCHANT_CONFIG_URL}/v0/merchants/${merchantId}`;
  const { data } = await getData(url);
  return data;
}

async function getProductCategories() {
  await delay(1200);
  const data = [
    'Product Category A',
    'Product Category B',
    'Product Category C',
    'Product Category D',
    'Product Category E',
  ];
  return data;
}

async function getMerchantData() {
  await delay(1300);
  const data = [
    {
      label: 'New Backlog',
      stack: 'merchantBacklog',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#b9d5df',
    },
    {
      label: 'Accepted Backlog',
      stack: 'merchantBacklog',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#c4dfaa',
    },
    {
      label: 'Production Backlog',
      stack: 'merchantBacklog',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#eeda90',
    },
    {
      label: 'Accepted Missed SLA',
      stack: 'merchantMissedSla',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#e6cfda',
    },
    {
      label: 'Production Missed SLA',
      stack: 'merchantMissedSla',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#ed9786',
    },
    {
      label: 'Shipped Missed SLA',
      stack: 'merchantMissedSla',
      data: [12, 19, 3, 25, 15],
      backgroundColor: '#df5c98',
    },
  ];
  return data;
}

async function getDates() {
  await delay(1300);
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return `${result.getDate()}/${result.getMonth()}/${result.getFullYear()}`;
  }
  let data = [];
  const date = new Date();
  for (let index = -10; index <= 7; index++) {
    data.push(addDays(date, index));
  }
  return data;
}

async function getNumberOfBacklogOrders() {
  await delay(1400);
  const dates = await getDates();
  const backgroundColor = new Array(dates.length).fill('#1f78b4', 0, dates.length);
  const data = [
    {
      type: 'line',
      label: 'Items Shipped',
      yAxisID: '2',
      backgroundColor: '#e58628',
      borderColor: '#e58628',
      borderWidth: 2,
      fill: false,
      data: [
        { x: dates[0], y: 150 },
        { x: dates[1], y: 175 },
        { x: dates[2], y: 200 },
        { x: dates[3], y: 67 },
        { x: dates[4], y: 150 },
        { x: dates[5], y: 279 },
        { x: dates[6], y: 100 },
        { x: dates[7], y: 175 },
        { x: dates[8], y: 50 },
        { x: dates[9], y: 200 },
        { x: dates[10], y: 335 },
      ],
    },
    {
      type: 'bar',
      label: 'Items to be shipped',
      yAxisID: '1',
      backgroundColor: backgroundColor.fill('#a6cee3', 11, dates.length),
      data: [
        { x: dates[0], y: 190.5 },
        { x: dates[1], y: 200 },
        { x: dates[2], y: 273 },
        { x: dates[3], y: 200 },
        { x: dates[4], y: 202 },
        { x: dates[5], y: 290 },
        { x: dates[6], y: 120 },
        { x: dates[7], y: 325 },
        { x: dates[8], y: 202 },
        { x: dates[9], y: 272 },
        { x: dates[10], y: 335 },
        { x: dates[11], y: 325 },
        { x: dates[12], y: 272 },
        { x: dates[13], y: 335 },
        { x: dates[14], y: 325 },
        { x: dates[15], y: 200 },
        { x: dates[16], y: 202 },
        { x: dates[17], y: 290 },
      ],
    },
  ];
  return data;
}

// Helper function for testing. Will be removed later
async function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export {
  getFulfillers,
  getFulfillerDetails,
  getFulfillerLogo,
  getUserPermissions,
  getUserAccessInfo,
  getMerchants,
  getMerchantData,
  getMerchantContact,
  getProductCategories,
  getDates,
  getNumberOfBacklogOrders,
  getMissedSla,
  getBacklog,
  getStarredFulfillers,
  updateStarredFulfillers,
  getFulfillerContacts,
  delay,
};
