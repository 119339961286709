import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactTablev6, Tooltip } from '@cimpress/react-components';
import IconRatingStar from '@cimpress-technology/react-streamline-icons/lib/IconRatingStar';
import IconUser from '@cimpress-technology/react-streamline-icons/lib/IconUser';
import IconEmailSend from '@cimpress-technology/react-streamline-icons/lib/IconEmailSend';
import { teal } from '@cimpress/react-components/lib/colors';
import Loading from './common/Loading';
import {
  getFulfillers,
  getStarredFulfillers,
  updateStarredFulfillers,
  getMissedSla,
  getFulfillerContacts,
} from '../services/apis';
import { showSnackbar } from '../actions/snackbar';
import { SNACKBAR_STATUS } from '../constants/snackbarStatus';

function MissedSla({ fulfillerId, showNumbers, showSnackbar }) {
  const [allApiData, setAllApiData] = useState();
  const [starredApiData, setStarredApiData] = useState();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [fulfillerList, setFulfillerList] = useState();
  const [starredFulfillers, setStarredFulfillers] = useState();
  const [page, setPage] = useState(0);

  const zeroes = [0, '0%'];

  const addColor = (value, color) => {
    return <span style={{ color: !zeroes.includes(value) ? color : '' }}>{value}</span>;
  };

  const columns = [
    { Header: 'Fulfiller Name', accessor: 'name' },
    {
      Header: 'On-time Items',
      accessor: 'ontime',
      Cell: row => addColor(row.value, 'green'),
    },
    {
      Header: 'Overall late',
      accessor: 'late',
      Cell: row => addColor(row.value, 'red'),
    },
    {
      Header: 'Shipped SLA missed',
      accessor: 'shipped',
      Cell: row => addColor(row.value, 'orange'),
    },
    {
      Header: 'Production SLA missed',
      accessor: 'production',
      Cell: row => addColor(row.value, 'orange'),
    },
    {
      Header: 'Accepted SLA missed',
      accessor: 'accepted',
      Cell: row => addColor(row.value, 'orange'),
    },
    { Header: 'Actions', accessor: 'actions' },
  ];

  const getLastRow = async fulfiller => {
    setTableLoading(true);
    const result = await getMissedSla([fulfiller]);
    setTableLoading(false);
    return result[0];
  };

  const toggleStarred = async (row, starred) => {
    let updatedStarredFulfillers = [];
    let updatedFulfillerList = [];
    let updatedAllApiData = [];
    let updatedStarredApiData = [];
    if (!starred && starredFulfillers.length === 10) {
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message: `Can't star more than 10 suppliers!`,
      });
      return;
    }
    if (starred) {
      //the action is to un-star that fulfiller
      updatedStarredFulfillers = starredFulfillers.filter(({ fulfillerId: id }) => id !== row.fulfillerId);
      updatedFulfillerList = [...fulfillerList, { fulfillerId: row.fulfillerId, name: row.name }];
      updatedStarredApiData = starredApiData.filter(({ fulfillerId: id }) => id !== row.fulfillerId);
      updatedAllApiData = allApiData.length < 20 ? [...allApiData, row] : [...allApiData];
    } else {
      //the action is to star that fulfiller
      if (starredFulfillers.length === 10) {
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: `Can't star more than 10 suppliers!`,
        });
        return;
      }
      updatedStarredFulfillers = [...starredFulfillers, { fulfillerId: row.fulfillerId, name: row.name }];
      updatedFulfillerList = fulfillerList.filter(({ fulfillerId: id }) => id !== row.fulfillerId);
      updatedStarredApiData = [...starredApiData, row];
      updatedAllApiData = allApiData.filter(({ fulfillerId: id }) => id !== row.fulfillerId);
      //Fetch one row of data to maintain consistent pageSize of table
      if (updatedFulfillerList.length >= 20 * (page + 1)) {
        updatedAllApiData.push(await getLastRow(updatedFulfillerList[20 * (page + 1)]));
      }
    }
    await updateStarredFulfillers(updatedStarredFulfillers);
    setFulfillerList(updatedFulfillerList);
    setStarredFulfillers(updatedStarredFulfillers);
    setAllApiData(updatedAllApiData);
    setStarredApiData(updatedStarredApiData);
  };

  const getContactEmails = async fulfiller => {
    const fulfillerContacts = await getFulfillerContacts(fulfiller);
    if (fulfillerContacts.length) {
      let operationalEmails = [],
        nonOperationalEmails = [];
      fulfillerContacts.map(contact => {
        if (contact.operationalSupportContact && contact.email) {
          operationalEmails.push(contact.email);
        } else if (contact.email) {
          nonOperationalEmails.push(contact.email);
        }
        return contact;
      });
      if (operationalEmails.length > 0) {
        window.location.href = `mailto:${operationalEmails.join(';')};`;
      } else if (nonOperationalEmails.length > 0) {
        window.location.href = `mailto:${nonOperationalEmails.join(';')};`;
      }
    } else {
      showSnackbar({
        status: SNACKBAR_STATUS.DANGER,
        message: 'There is no Fulfiller Email present!',
      });
    }
  };

  const formatData = ({ data, starred = false }) => {
    return data.map(row => {
      const late = row.shipped + row.production + row.accepted;
      let data = {
        ...row,
        late,
        actions: (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Tooltip
              direction='top'
              contents={starred ? 'Unstar Fulfiller' : 'Star Fulfiller'}
              style={{ marginRight: '30px' }}
            >
              <IconRatingStar
                color={teal.base}
                weight={starred ? 'fill' : ''}
                style={{ cursor: 'pointer' }}
                onClick={() => toggleStarred(row, starred)}
              />
            </Tooltip>
            <Tooltip direction='top' contents='View Fulfiller Profile' style={{ marginRight: '30px' }}>
              <IconUser color={teal.base} />
            </Tooltip>
            <Tooltip direction='top' contents='Email Fulfiller' style={{ marginRight: '10px' }}>
              <IconEmailSend
                color={teal.base}
                onClick={() => {
                  getContactEmails(row.fulfillerId);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
      if (!showNumbers) {
        const total = row.ontime + late;
        data = {
          ...data,
          ontime: `${((row.ontime / total) * 100).toFixed(1)}%`,
          late: `${((late / total) * 100).toFixed(1)}%`,
          shipped: `${((row.shipped / total) * 100).toFixed(1)}%`,
          production: `${((row.production / total) * 100).toFixed(1)}%`,
          accepted: `${((row.accepted / total) * 100).toFixed(1)}%`,
        };
      }
      return data;
    });
  };

  const pageChangeHandler = async offset => {
    setTableLoading(true);
    const data = await getMissedSla(fulfillerList.slice(20 * offset, 20 * (offset + 1)));
    setPage(offset);
    setAllApiData(data);
    setTableLoading(false);
  };

  useEffect(() => {
    const initializeTables = async () => {
      setLoading(true);
      const [fulfillers, starredFulfillerData] = await Promise.all([getFulfillers(), getStarredFulfillers()]);
      // Removing starred fulfillers from the main fulfiller list to prevent overlap.
      const reducedFulfillerList = fulfillers
        .filter(({ fulfillerId }) => !starredFulfillerData.some(x => x.fulfillerId === fulfillerId))
        .map(({ fulfillerId, name }) => ({ fulfillerId, name }));
      const [allData, starredData] = await Promise.all([
        getMissedSla(reducedFulfillerList.slice(0, 20)),
        getMissedSla(starredFulfillerData),
      ]);
      setAllApiData(allData);
      setStarredApiData(starredData);
      setStarredFulfillers(starredFulfillerData);
      setFulfillerList(reducedFulfillerList);
      setLoading(false);
      setTableLoading(false);
    };
    initializeTables();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>
            {starredApiData?.length > 0 && (
              <>
                <h3 className='sub-heading'>Starred Fulfillers:</h3>
                <div style={{ maxHeight: '30vh', overflow: 'scroll' }}>
                  <ReactTablev6
                    columns={columns}
                    data={starredApiData && formatData({ data: starredApiData, starred: true })}
                    showPagination={false}
                    minRows={0}
                  />
                </div>
              </>
            )}
            {allApiData?.length > 0 && (
              <>
                <h3 className='sub-heading'>All Fulfillers:</h3>
                <ReactTablev6
                  columns={columns}
                  data={allApiData && formatData({ data: allApiData })}
                  loading={tableLoading}
                  pages={fulfillerList.length / 20}
                  pageSize={20}
                  minRows={0}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  onPageChange={e => pageChangeHandler(e)}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default connect(null, { showSnackbar })(MissedSla);
