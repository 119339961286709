export const ACCOUNT_ID = 'https://claims.cimpress.io/account';

export const CANONICAL_ID = 'https://claims.cimpress.io/canonical_id';

export const STARRED_FULFILLERS = 'STARRED_FULFILLERS';

export const CUSTOMIZR_OPERATIONS_KEY = 'FulfillerHealth-Operations';

export const PAGE_SIZE = 100;

export const OPERATIONS_HEADINGS = {
  AWAITING_FULFILLER_RESPONSE: 'Awaiting Fulfiller Response',
  PRODUCTION_ACCEPTED: 'Production Accepted',
  PRODUCTION: 'Production',
  TOTAL_BACKLOG: 'Total Backlog',
};
