import { getUserAccessInfo } from '../services/apis';
import { ISSUE } from '../constants/view';

const fulfillerView = async (to, from, next) => {
  try {
    const { fulfillerId } = to.match.params;
    const { userHasMerchantAccess, userHasSameAccountAsFulfiller } = await getUserAccessInfo({ fulfillerId });
    // The principal must have "read:order" permission(s) on fulfillers resource for requested fulfillerId identifier.
    // OR "read:order" on at least one merchant
    if (userHasSameAccountAsFulfiller) {
      next.props({
        supplierOnlyView: true,
        // if user doesn't have merchant access then he is supplier-only user
        supplierOnlyUser: !userHasMerchantAccess,
      });
    } else if (userHasMerchantAccess) {
      next();
    }
    next.redirect(`/${ISSUE.FORBIDDEN}`);
  } catch (error) {
    console.error(error?.response?.data || error?.response || error);
    if (error?.response?.status === 404) next(); // show merchant fulfiller view
    throw error;
  }
};

export default fulfillerView;
