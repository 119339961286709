import { SNACKBAR_STATUS } from '../constants/snackbarStatus';
import { Types } from '../actions/snackbar';

export const INITIAL_STATE = {
  show: false,
  status: SNACKBAR_STATUS.INFO,
  message: '',
};

export default function SnackbarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_SNACKBAR: {
      return {
        ...state,
        show: true,
        ...action.payload,
      };
    }
    case Types.HIDE_SNACKBAR: {
      return {
        ...state,
        show: false,
        ...action.payload,
      };
    }
    default:
      return { ...state };
  }
}
