import './Shell.css';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import auth from '../../services/auth';
import { VIEWS } from '../../constants/view';
import { Loudspeaker, LoudspeakerEnvironment } from '@cimpress-technology/react-loudspeaker';
import { getUserAccessInfo } from '../../services/apis';
import { showSnackbar } from '../../actions/snackbar';
import { SNACKBAR_STATUS } from '../../constants/snackbarStatus';
import Loading from './Loading';
import ViewPort from './ViewPort';

function Shell({ children, showSnackbar }) {
  const location = useLocation();
  const [path, subPath] = location?.pathname?.split('/')?.slice(1) || [];

  const [loading, setLoading] = useState(false);
  const [appLinks, setAppLinks] = useState([]);
  useEffect(() => {
    const generateLinks = async () => {
      setLoading(true);
      try {
        const { userHasMerchantAccess, userFirstFulfillerId } = await getUserAccessInfo();
        const links = [];
        if (userHasMerchantAccess) {
          links.push(
            {
              id: 'performance',
              content: <Link to={`/${VIEWS.PERFORMANCE}`}>Performance</Link>,
              isActive: Boolean(!subPath && path === VIEWS.PERFORMANCE),
            },
            {
              id: 'operations',
              content: <Link to={`/${VIEWS.OPERATIONS}`}>Operations</Link>,
              isActive: Boolean(!subPath && path === VIEWS.OPERATIONS),
            },
          );
          if (userFirstFulfillerId) {
            links.push({
              id: 'supplier-view',
              content: (
                <Link
                  to={`/${path === VIEWS.OPERATIONS ? VIEWS.OPERATIONS : VIEWS.PERFORMANCE}/${userFirstFulfillerId}`}
                >{`Supplier's View`}</Link>
              ),
              isActive: Boolean(path && subPath),
            });
          }
        } else if (userFirstFulfillerId) {
          links.push(
            {
              id: 'performance',
              content: <Link to={`/${VIEWS.PERFORMANCE}/${userFirstFulfillerId}`}>Performance</Link>,
              isActive: Boolean(path === VIEWS.PERFORMANCE),
            },
            {
              id: 'operations',
              content: <Link to={`/${VIEWS.OPERATIONS}/${userFirstFulfillerId}`}>Operations</Link>,
              isActive: Boolean(path === VIEWS.OPERATIONS),
            },
          );
        }
        setAppLinks(links);
      } catch (error) {
        console.error(error?.response?.data || error?.response || error);
        showSnackbar({
          status: SNACKBAR_STATUS.DANGER,
          message: 'Error while fetching user permissions! Try reloading.',
        });
      }
      setLoading(false);
    };

    generateLinks();
  }, [showSnackbar, path, subPath]);

  return (
    <CssLoader>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header
            responsive
            accessToken={auth.getAccessToken()}
            appTitle={
              <Link className='navTitle' to='/'>
                Supplier Performance
              </Link>
            }
            appLinks={appLinks}
            profile={auth.getProfile()}
            onLogInClicked={() => auth.login()}
            onLogOutClicked={() => auth.logout('/')}
            isLoggedIn={auth.isLoggedIn()}
            documentationUrl={process.env.REACT_APP_DOCUMENTATION_LINK}
          />
          <Loudspeaker
            accessToken={auth.getAccessToken()}
            channelIds={[process.env.REACT_APP_LOUDSPEAKER_CHANNEL]}
            title={`What's new in Supplier Performance`}
            environment={
              process.env.REACT_APP_ENV === 'prd' ? LoudspeakerEnvironment.Production : LoudspeakerEnvironment.Staging
            }
            showInlineFeedback={false}
            showManageSubscriptionsButton={false}
          />
          <div style={{ minHeight: 'calc(100vh - 225px)' }}>
            <div className='container-fluid'>
              <ViewPort>{children}</ViewPort>
            </div>
          </div>
        </>
      )}
    </CssLoader>
  );
}

export default connect(null, { showSnackbar })(Shell);
